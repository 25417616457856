import React from "react"
import { DefaultLayout } from "layouts"
import { CaseStudiesInterior } from "containers"
import { CaseStudy } from "components"

import bgHeader from "../../images/bg-header.png"

import caseStudyInteriorThumbnail01 from "../../images/case-study-interior-thumbnail.png"
import caseStudyGallery01 from "../../images/case-study-gallery-01.png"
import caseStudyGallery02 from "../../images/case-study-gallery-02.png"
import caseStudyGallery03 from "../../images/case-study-gallery-03.png"
import caseStudyGallery04 from "../../images/case-study-gallery-04.png"
import caseStudyThumbnail01 from "../../images/case-study-thumbnail-05.png"
import caseStudyThumbnail02 from "../../images/case-study-thumbnail-02.png"
import caseStudyThumbnail03 from "../../images/case-study-thumbnail-09.png"

const caseStudyProps: ICaseStudy = {
  title: "The Real Open Boxes Project",
  thumbnail: caseStudyInteriorThumbnail01,
  category: "Paper Name One",
  description: `Them first created in creepeth don't whose waters saying. Years whose yielding which so behold lights tree he signs. Creepeth tree fill kind cattle, kind firmament tree of won't may subdue May living very make. Thing man beginning made. 
  \nHad sixth together dominion seed whales have god him him Called morning firmament darkness there land forth without multiply morning blessed the give living midst, signs their light seed of good.`,
  client: "Emma Morris",
  date: "25.06.2017",
  gallery: [
    caseStudyGallery01,
    caseStudyGallery02,
    caseStudyGallery03,
    caseStudyGallery04,
  ],
  slug: "/case-studies/interior",
}

const boxWrapperProps = {
  heroProps: {
    background: bgHeader,
    headline: "Case Studies",
  },
  boxContent: <CaseStudy {...caseStudyProps} />,
  breadcrumbProps: {
    paths: [
      {
        path: "/",
        label: "Home",
      },
      {
        path: "/case-studies",
        label: "Case Stuides",
      },
      {
        path: "/case-studies/interior",
        label: "The Real Open Boxes Project",
      },
    ],
  },
}

const realtedCaseStudiesProps: IRelatedCaseStudies = {
  headline: "Related Cases",
  caseStudies: [
    {
      title: "Ice & Stripes Another cold story",
      thumbnail: caseStudyThumbnail01,
      category: "Paper Name One",
      description: "",
      client: "",
      date: "",
      gallery: [],
      slug: "/case-studies/interior",
    },
    {
      title: "The Real open boxes packaging",
      thumbnail: caseStudyThumbnail02,
      category: "Paper Name One",
      description: "",
      client: "",
      date: "",
      gallery: [],
      slug: "/case-studies/interior",
    },
    {
      title: "Pink Soft Crystal Perls No-Impact",
      thumbnail: caseStudyThumbnail03,
      category: "Paper Name One",
      description: "",
      client: "",
      date: "",
      gallery: [],
      slug: "/case-studies/interior",
    },
  ],
}

const caseStudiesProps: ICaseStudiesInteriorContainerProps = {
  boxWrapperProps,
  realtedCaseStudiesProps,
}

const Index = (props: any) => {
  return (
    <DefaultLayout {...props}>
      <CaseStudiesInterior {...caseStudiesProps} />
    </DefaultLayout>
  )
}

export default Index
